
  import { Component, Prop } from 'vue-property-decorator'
  import { Negotiation } from '@/entities/purchase'
  import { fixPrice } from '@genio/automotive-console/src/utils/general'
  import { AppraisalView } from '@/components/forms/view/AppraisalView'

@Component({
  methods: { fixPrice },
})
  export default class NegotiationSupervisor extends AppraisalView {
  @Prop({ type: [String, Number, Object, Array] }) value!: any

  // Methods

  // Getters
  get negotiation (): Negotiation {
    const { value } = this
    return value as Negotiation
  }

  get publicationAmount () {
    const { negotiation } = this
    return negotiation?.inspection?.appraisal?.expectedPublicationAmount
  }

  get inspectionDiscount () {
    const { negotiation } = this
    return negotiation?.inspectionAmount?.value
  }

  get negotiationAuthorizedConsignment () {
    const { negotiation } = this
    return negotiation?.negotiationResponseAmountConsignment.value
  }

  get negotiationAuthorizedPurchase () {
    const { negotiation } = this
    return negotiation?.negotiationResponseAmount.value
  }

  get negotiationResponseAppealed () {
    const { negotiation } = this
    return negotiation?.negotiationResponseAppealed
  }

  get negotiationResponseAppealedConsignment () {
    const { negotiation } = this
    return negotiation?.negotiationResponseAppealedConsignment
  }

  get lastAppraisalConsignmentResponse () {
    const { negotiation } = this
    return negotiation?.inspection.appraisal.lastAppraisalConsignmentResponse
  }

  get lastAppraisalResponse () {
    const { negotiation } = this
    return negotiation?.inspection.appraisal.lastAppraisalResponse
  }

  get differencePurchase () {
    const { negotiationAuthorizedPurchase, negotiationResponseAppealed } = this

    if (!negotiationResponseAppealed?.value || !negotiationAuthorizedPurchase) return 0

    return (negotiationResponseAppealed.value || 0) - negotiationAuthorizedPurchase
  }

  get differenceConsignment () {
    const { negotiationAuthorizedConsignment, negotiationResponseAppealedConsignment } = this

    if (!negotiationResponseAppealedConsignment?.value || !negotiationAuthorizedConsignment) return 0

    return (negotiationResponseAppealedConsignment?.value || 0) - negotiationAuthorizedConsignment
  }

  get discountInspectionConsignment () {
    const { inspectionDiscount, lastAppraisalConsignmentResponse } = this

    return lastAppraisalConsignmentResponse.value - inspectionDiscount
  }

  get discountInspectionPurchase () {
    const { inspectionDiscount, lastAppraisalResponse } = this

    return lastAppraisalResponse.value - inspectionDiscount
  }

  get displayPurchase () {
    const { maxMileage, negotiation } = this

    return this.displayPurchaseFields(negotiation?.inspection?.appraisal?.kms, maxMileage)
  }
  }
